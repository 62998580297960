import React, { useEffect, useState } from "react";
import UploadIcon from "assets/uploadIcon.svg";
import { fileExtension } from "utils/fileextension";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import { trySampleText } from "utils/sampletext-generator";
import useAutoFocus from "hooks/useAutoFocus";

const ToolInput = ({
  handleClick,
  result,
  isButtonDisabled,
  ResetButton,
  loading,
  handleReset,
  setText,
  buttontext,
  setWordCount,
  lang,
  text,
  wordCount,
  children,
  divHeight,
  placeholder,
  isGuidance = false,
  isContextual = false,
  isTranslation,
  refDiv,
  tooltype,
  urlText,
  setUrlText,
  clearLink,
  handleLinkChange,
  isEnterText,
  isResult = false,
  isContextualGlossary = false,
  setIsEnterText,

}) => {
  const [isLgScreen, setIsLgScreen] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [textLoader, setTextLoader] = useState(false);
  const [file, setFile] = useState(false);
  useAutoFocus("textarea");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsLgScreen(window.innerWidth > 1024);
      const handleResize = () => {
        setIsLgScreen(window.innerWidth > 1024);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  useEffect(() => {
    const wordLength = (text || "")
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;

    setWordCount(wordLength);
  }, [text, setWordCount]);

  const handleInputTextChange = (e) => {
    const newText = e.target.value;

    setText(newText);
  };

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);
    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);
      setText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };
  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    setUrlText("");
    setText("");
  };
  return (
    <div
      ref={refDiv}
      className={`relative ${
        isGuidance ? "w-full lg:w-[30%]" : "w-full"
      } w-full p-3 bg-white rounded-lg lg:rounded-l-lg shadow-lg lg:shadow-none border-none mt-4 lg:mt-0`}
    >
      { !isContextualGlossary && (
      <div>{children}</div>
    )}
      {isEnterText || isResult  ? (
        <div>
          {fileUploadLoading ? (
            <FileLoadingInput
              file={file}
              isGuidance={isGuidance}
              divHeight={divHeight}
              isLgScreen={isLgScreen}
            />
          ) : sizeLimitError && !fileUploadLoading ? (
            <ReUploadFile
              file={file}
              setFile={setFile}
              setSizeLImitError={setSizeLImitError}
              uploadFile={uploadFile}
            />
          ) : uploadError && !fileUploadLoading ? (
            <UnsupportedFile
              file={file}
              setSizeLImitError={setSizeLImitError}
              setFile={setFile}
              uploadFile={uploadFile}
              setFileUploadError={setUploadError}
            />
          ) : (
            <div className="relative">
              <textarea
                autoFocus
                style={{
                  minHeight: isLgScreen
                    ? isGuidance
                      ? `183px`
                      : divHeight
                      ? `${divHeight}px`
                      : `230px`
                    : "230px",
                }}
                id="input-textarea"
                dir={
                  lang === "fa" || lang === "he" || lang === "ar"
                    ? "rtl"
                    : "ltr"
                }
                className={`anonymize_tool font-primary  placeholder-[#B4B4B4] text-black leading-[26px]  ${
                  isContextual ? "pr-8" : ""
                } py-3 bg-transparent text-base overscroll-y-none resize-none border-none outline-none block w-full`}
                placeholder={textLoader ? "" : placeholder}
                value={text}
                dangerouslySetInnerHTML={{
                  __html: text.replace(/\n/g, "<br />"),
                }}
                onChange={handleInputTextChange}
              />
              {tooltype === "pre" && (
                <>
                  {textLoader && (
                    <div className="absolute top-[13px] left-[14px] font-opensans text-[#B4B4B4] italic">
                      Adding sample text...
                    </div>
                  )}
                  {!text && !textLoader && (
                    <button
                      className={`absolute top-[13px] left-[180px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto`}
                      onClick={() => trySampleText(setText, setTextLoader)}
                    >
                      Try Sample Text
                    </button>
                  )}
                </>
              )}
              {isContextualGlossary && (
              <div className="absolute bottom-[-40px] right-0">
              <div className="flex gap-3">
                    <button
                      onClick={() => toggleInput(true)}
                      className={`py-1 px-3 rounded-md font-semibold ${
                        isEnterText
                          ? "text-white bg-[#7AA7FF]"
                          : "text-[#7AA7FF] bg-[#F0F5FF]"
                      }`}
                    >
                      Source Text
                    </button>
                    <button
                      onClick={() => toggleInput(false)}
                      className={`py-1 px-3 rounded-md font-semibold ${
                        isEnterText
                          ? "text-[#7AA7FF] bg-[#F0F5FF]"
                          : "text-white bg-[#7AA7FF]"
                      }`}
                    >
                      Enter URL
                    </button>
                  </div>
              </div>
              )}

              {isContextualGlossary  && (
                <div>
                  {children}
                </div>
              )}
            </div>
          )}
          <div className="flex justify-between items-center py-4">
            {text ? (
              <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                {wordCount} words
              </p>
            ) : (
              <>
                <label
                  htmlFor={`uploadFile ${isTranslation}`}
                  className="flex gap-2 items-center cursor-pointer "
                >
                  <img src={UploadIcon} alt="upload_icon" />
                  <span className="">Upload File</span>
                </label>
                <input
                  type="file"
                  className="hidden"
                  id={`uploadFile ${isTranslation}`}
                  onChange={uploadFile}
                />

              </>
            )}
          </div>

         
        </div>
      ) : (
        <div>
          <textarea
            style={{
              minHeight: isLgScreen
                ? isGuidance
                  ? `183px`
                  : divHeight
                  ? `${divHeight}px`
                  : `230px`
                : "230px",
            }}
            name="url"
            value={urlText }
            placeholder="Paste URL here"
            className={` font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
            onChange={handleLinkChange}
            readOnly={loading}
          />
          <div className="flex justify-between items-center p-4 mt-8">
            <span></span>
            <div className="flex gap-2">
              <button
                type="button"
                className={` ${
                  urlText?.length > 0
                    ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                    : "border border-[#B0B0B0] text-[#B0B0B0]"
                } flex gap-2 bg-white px-4 py-2 rounded-md`}
                onClick={clearLink}
                disabled={urlText?.length === 0}
              >
                Clear link
              </button>
              {isContextualGlossary && (
               <div className="flex gap-3">
                    <button
                      onClick={() => toggleInput(true)}
                      className={`py-1 px-3 rounded-md font-semibold ${
                        isEnterText
                          ? "text-white bg-[#7AA7FF]"
                          : "text-[#7AA7FF] bg-[#F0F5FF]"
                      }`}
                    >
                      Source Text
                    </button>
                    <button
                      onClick={() => toggleInput(false)}
                      className={`py-1 px-3 rounded-md font-semibold ${
                        isEnterText
                          ? "text-[#7AA7FF] bg-[#F0F5FF]"
                          : "text-white bg-[#7AA7FF]"
                      }`}
                    >
                      Enter URL
                    </button>
                  </div>
                  )}
            </div>
          </div>
        </div>
      )}
      {isGuidance && (
        <div className="flex gap-1">
          <button
            onClick={handleClick}
            disabled={!!result}
            className={`${
              loading
                ? "bg-[#E8E8E8] text-[#858585]"
                : result
                ? "bg-[#E8E8E8] text-[#858585]"
                : "bg-[#5B93FF] text-white"
            }  ${
              isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : ""
            }   text-base font-opensans text-center rounded-md px-5 py-3 w-full `}
          >
            {buttontext}
          </button>

          <button
            disabled={!result}
            className={`  ${result ? "cursor-pointer" : "cursor-not-allowed"}`}
            onClick={handleReset}
          >
            <img src={ResetButton} alt="reset" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ToolInput;
